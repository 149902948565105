<template>
  <div class="blue-grey lighten-5" style="width:100%;height:100%">
    <div
      v-if="plzWechat === 'yesWechat' && system_name"
      class="blue-grey lighten-5 px-6 py-16 d-flex flex-column align-center justify-space-between unselect"
      style="width:100vw;height:100vh"
    >
      <div class="d-flex flex-column align-center mt-6">
        <v-sheet
          color="white"
          elevation="2"
          height="120"
          width="120"
          class="rounded-circle"
        >
          <v-img
            contain
            width="120"
            height="120"
            class="rounded-circle"
            src="../../assets/avatar.png"
          ></v-img>
        </v-sheet>
        <div class="black--text text-h6 text-center mt-11">
          <div>请授权</div>
          <div>{{ system_name }}</div>
        </div>
      </div>
    </div>

    <div v-if="plzWechat === 'noWechat'" class="pt-12">
      <div class="text-h6 text-center pt-12">请使用微信扫码</div>
    </div>
    <Snackbar />
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import { api_request } from "@/util/network";
import { parseQuery } from "@/util/util";

export default {
  name: "Authorization",
  data() {
    return {
      id: null,
      system_name: null,
      plzWechat: null
    };
  },
  async created() {
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == "micromessenger") {
      this.plzWechat = "yesWechat";
      await this.getSchoolName();
      await this.filterId();
      this.doAuth();
    } else {
      this.plzWechat = "noWechat";
    }
  },
  methods: {
    async getSchoolName() {
      return this.$http
        .get(`api/system_config`)
        .delegateTo(api_request)
        .then(({ system_name }) => {
          this.system_name = system_name ? system_name : "瑞智康诚WebEXP";
        })
        .catch(({ code, message }) => {
          throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    async filterId() {
      return this.$http
        .get(`api/settings/login`)
        .delegateTo(api_request)
        .then(res => {
          let { qrcode } = res;
          this.id = qrcode;
        })
        .catch(({ code, message }) => {
          throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    async doAuth() {
      if (this.id) {
        let curUrl = location.search.substr(1);
        let session = parseQuery(curUrl)["session"];
        this.$http
          .get(
            `api/source/${this.id}/start?session_id=${session}&wechat_qrcode=true`
          )
          .delegateTo(api_request)
          .then(data => {
            let originName = location.origin;
            let {
              content: { appid, state }
            } = data;

            localStorage.setItem("session_id", session);

            let URL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${originName}${process.env.VUE_APP_BASE_URL}/wxwork/callback&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`;
            // let URL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${originName}${process.env.VUE_APP_BASE_URL}/wxwork/callback&response_type=code&scope=snsapi_privateinfo&state=${state}#wechat_redirect`;
            window.location = URL.replace(/#/g, `&v=${new Date().getTime()}#`); //清缓存
          })
          .catch(({ code, message }) => {
            if (code === "SessionIdExpired") {
              this.$router.push({
                path: "/errorprompt",
                query: {
                  type: "SessionIdExpired"
                }
              });
            } else {
              throw `获取数据失败：${this.$t(
                "api." + code
              )}, 额外信息: ${this.$t(
                "api." + typeof message === "string"
                  ? message
                  : JSON.stringify(message)
              )}`;
            }
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    }
  },
  components: {
    Snackbar
  }
};
</script>

<style lang="less" scoped>
.v-application .text-h6 {
  font-family: system-ui !important;
}
</style>
